import { findVesting } from '../../helpers/scripts/vesting';
const intialState = {
  address: '',
};

export const SET_ADDRESS = 'SET_ADDRESS';
export const FETCH_VESTING_DETAILS = 'FETCH_VESTING_DETAILS';
export const FETCH_VESTING_DETAILS_FAILED = 'FETCH_VESTING_DETAILS_FAILED';
export const FETCH_VESTING_DETAILS_SUCCESS = 'FETCH_VESTING_DETAILS_SUCCESS';

export const setUserAddress = (address) => {
  return (dispatch) => {
    dispatch({
      type: SET_ADDRESS,
      payload: {
        address,
      },
    });
  };
};

export const findVestingDetails = (address) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({
        type: FETCH_VESTING_DETAILS,
      });
      response = await findVesting(address);
      const {
        success = false,
        vestingContractAddress = '',
        details = {},
      } = response || {};
      if (success) {
        dispatch({
          type: FETCH_VESTING_DETAILS_SUCCESS,
          payload: {
            vestingContractAddress,
            details,
          },
        });
      } else {
        dispatch({
          type: FETCH_VESTING_DETAILS_FAILED,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_VESTING_DETAILS_FAILED,
        payload: error?.message,
      });
    }
    return response;
  };
};

export default function (state = intialState, action) {
  const { type, payload = {} } = action || {};

  switch (type) {
    case SET_ADDRESS:
      return {
        ...state,
        address: payload?.address,
      };
    case FETCH_VESTING_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VESTING_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        vestingContractAddress: '',
        vestingDetails: {},
      };
    case FETCH_VESTING_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        vestingContractAddress: payload?.vestingContractAddress || '',
        vestingDetails: payload?.details || {},
      };
    default:
      return state;
  }
}
