import Web3 from 'web3';
// import * as _ from 'lodash';
let web3 = new Web3(Web3.givenProvider);

export const connectWallet = async () => {
  try {
    if (typeof window.web3 != 'undefined') {
      web3 = new Web3(window.web3.currentProvider);
      //console.log('provider=', web3.currentProvider);
    } else {
      console.log('undefined', web3);
      return { status: false };
      //console.log('login into your ethereum wallet');
    }
    let account = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    account = account[0];
    console.log('account=', account);
    return { status: true };
  } catch (error) {
    console.log('error', error?.message);
    return { status: false };
  }
};

export const getTransactionConfirmation = (hash, type = '') => {
  try {
    var info;
    console.log('transaction confirmation callled>>2', hash);
    return new Promise((resolve, reject) => {
      var refreshIntervalId = setInterval(fname, 1000);
      async function fname() {
        console.log('transaction confirmation receipt<>>>>2');
        if (info == null) {
          info = await web3.eth.getTransactionReceipt(hash);
          console.log('info', info);
        } else {
          clearInterval(refreshIntervalId);
          console.log('transaction confirmation receipt', info);
          if (info.status) {
            return resolve({
              success: true,
            });
          } else {
            return reject({ success: false });
          }
        }
      }
    });
  } catch (e) {
    console.log('e=', e);
    return { success: false };
  }
};
