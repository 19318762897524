import { combineReducers } from 'redux';
import user from './user';
import modal from './modals';

const rootReducer = combineReducers({
  user,
  modal,
});

export default rootReducer;
