import { getTransactionConfirmation } from '../../helpers/scripts/wallet';

import { release } from '../../helpers/scripts/vesting';

const initialState = {
  isRequesting: false,
  modalType: '',
  error: '',
  hasError: false,
  visible: false,
  entity_id: null,
  data: {},
};

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const WITHDRAW_TOKEN = 'WITHDRAW_TOKEN';
export const WITHDRAW_TOKEN_FAILED = 'WITHDRAW_TOKEN_FAILED';
export const WITHDRAW_TOKEN_SUCCESS = 'WITHDRAW_TOKEN_SUCCESS';

export const openModal = (modalType, entity_id = null, data = {}) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_MODAL,
      payload: {
        modalType,
        data,
        entity_id,
      },
    });
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };
};

export const withdrawToken = (vestingContractAddress, amount, address) => {
  return async (dispatch) => {
    let response = {};
    let res = {};
    console.log(
      'withdraw values called>>>',
      vestingContractAddress,
      amount,
      address
    );
    try {
      dispatch({
        type: WITHDRAW_TOKEN,
      });
      response = await release(vestingContractAddress, amount, address);
      const { status = false, hash = '', err = '' } = response || {};
      if (status) {
        res = await getTransactionConfirmation(hash);
        const { success = false } = res || {};
        if (success) {
          dispatch({
            type: WITHDRAW_TOKEN_SUCCESS,
          });
        } else {
          dispatch({
            type: WITHDRAW_TOKEN_FAILED,
          });
        }
      } else {
        dispatch({
          type: WITHDRAW_TOKEN_FAILED,
          payload: err,
        });
      }
    } catch (error) {
      dispatch({
        type: WITHDRAW_TOKEN_FAILED,
        payload: error?.message,
      });
    }
    return res;
  };
};

export default function (state = initialState, action) {
  const { type, payload } = action || {};

  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalType: payload?.modalType || '',
        data: payload?.data || {},
        visible: true,
        entity_id: payload?.entity_id,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        visible: false,
        modalType: '',
        data: {},
        entity_id: null,
      };
    case WITHDRAW_TOKEN:
      return {
        ...state,
        isRequesting: true,
      };
    case WITHDRAW_TOKEN_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: '',
      };
    case WITHDRAW_TOKEN_FAILED:
      return {
        ...state,
        isRequesting: false,
        error: payload,
      };
    default:
      return state;
  }
}
