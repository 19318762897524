import React, { Component } from 'react';
import { Progress, Button, message } from 'antd';
import { connect } from 'react-redux';
import './styles.less';
import { MODALS } from '../../constants';
import { openModal } from '../../redux/modals';
import { findVestingDetails } from '../../redux/user';
import {
  getTotalStreamedPercent,
  getTotalStreamedAmount,
  getTotalReleasedPercent,
} from '../../utils/streamDetails';
import moment from 'moment';

class Vesting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProgressDetails = () => {
    const { user: { vestingDetails = {} } = {} } = this.props;
    const { _vestedAmount } = vestingDetails || {};
    console.log(
      'total stream value>?>>>',
      parseFloat(getTotalStreamedAmount(vestingDetails)).toFixed(6)
    );
    return (
      <div className='flex flex-col justify-start items-center vesting-details'>
        <p className='bold text-white text-3xl'>D11</p>
        <p className='total-vested flex items-center justify-center text-5xl semi-bold'>
          {!isNaN(getTotalStreamedAmount(vestingDetails))
            ? parseFloat(getTotalStreamedAmount(vestingDetails)).toFixed(6)
            : parseFloat(0).toFixed(2)}
        </p>
        <p className='semi-bold text-white text-lg'>
          /
          {!isNaN(_vestedAmount / 1e18)
            ? parseFloat(_vestedAmount / 1e18).toFixed(2)
            : parseFloat(0).toFixed(2)}{' '}
          D11 Total
        </p>
      </div>
    );
  };

  renderTotalStreamed = () => {
    const { user: { vestingDetails = {} } = {} } = this.props;
    const { _releasedAmount } = vestingDetails || {};
    console.log(
      'total streamed amount >>>',
      isNaN(getTotalStreamedAmount(vestingDetails))
    );
    return (
      <div className='flex row justify-between items-center mt-10 footer'>
        <div className='flex-1'>
          <p
            className='bold text-base text-white mb-0 text-xl'
            style={{
              borderBottom: '2px solid #136BFF',
              marginBottom: '2px',
            }}>
            Streamed:{' '}
            {!isNaN(getTotalStreamedPercent(vestingDetails))
              ? parseFloat(getTotalStreamedPercent(vestingDetails)).toFixed(2)
              : 0}
            %
          </p>
          <p className='medium text-base text-white mb-0'>
            {isNaN(getTotalStreamedAmount(vestingDetails))
              ? 0
              : parseFloat(getTotalStreamedAmount(vestingDetails)).toFixed(
                  4
                )}{' '}
            <span className='bold'>D11</span>
          </p>
        </div>
        <div className='flex-1 flex row justify-center'>
          <Button
            className='defi11-primary-btn'
            onClick={this.openWithdrawToken}>
            RELEASE
          </Button>
        </div>

        <div className='flex-1'>
          <p
            className='bold text-base text-white mb-0 text-xl'
            style={{
              borderBottom: '2px solid #F77927',
              marginBottom: '2px',
            }}>
            Released:{' '}
            {!isNaN(getTotalReleasedPercent(vestingDetails))
              ? parseFloat(getTotalReleasedPercent(vestingDetails)).toFixed(2)
              : parseFloat(0).toFixed(2)}
            %
          </p>
          <p className='medium text-base text-white mb-0'>
            {!isNaN(parseFloat(_releasedAmount / 1e18).toFixed(4))
              ? parseFloat(_releasedAmount / 1e18).toFixed(4)
              : 0}{' '}
            <span className='bold'>D11</span>
          </p>
        </div>
      </div>
    );
  };

  openWithdrawToken = () => {
    const { user: { vestingDetails = {} } = {} } = this.props;
    const { _cliff, _withdrawalAllowed = true } = vestingDetails || {};
    console.log(
      'vesting detai;ls check>>>>>1',
      _cliff,
      moment().unix(),
      new Date(_cliff * 1000).toGMTString()
    );
    if (!_withdrawalAllowed) {
      message.error('Release is paused for now', 3);
      return;
    }
    if (Number(_cliff) > Math.round(new Date() / 1000)) {
      message.error(
        `Funds will release on ${new Date(_cliff * 1000).toGMTString()}`,
        3
      );
      return;
    }
    this.props.openModal(MODALS.WITHDRAW_TOKENS);
  };

  render() {
    const { user: { vestingDetails = {} } = {} } = this.props;
    const { _cliff } = vestingDetails || {};
    console.log(
      'vesting detai;ls check>>>>>',
      _cliff,
      moment().unix(),
      new Date(_cliff * 1000)
    );
    return (
      <div className='medium vesting mt-10'>
        {this.renderProgressDetails()}
        <Progress
          type='circle'
          percent={getTotalStreamedPercent(vestingDetails) || 0}
          strokeColor={'#136BFF'}
          strokeWidth={3}
          trailColor={'#fff'}
          style={{
            width: '500px',
            height: '500px',
          }}
        />
        <Progress
          type='circle'
          percent={getTotalReleasedPercent(vestingDetails) || 0}
          strokeColor={'#F77927'}
          strokeWidth={3}
          trailColor={'#fff'}
          className='second-progress'
          style={{
            width: '426px',
            height: '430px',
          }}
        />
        {this.renderTotalStreamed()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal = {}, user = {} } = state;
  return {
    modal,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modalType) => dispatch(openModal(modalType)),
    findVestingDetails: (address) => dispatch(findVestingDetails(address)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vesting);
