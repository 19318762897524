import moment from 'moment';
export const getTotalStreamedPercent = (vestingDetails = {}) => {
  const { _cliff, _duration, _start, _vestedAmount } = vestingDetails || {};
  const totalReleasedAmount =
    (_vestedAmount * (moment().unix() - _start)) / _duration;

  if (totalReleasedAmount !== NaN) {
    const amount = totalReleasedAmount / 1e18;
    const totalReleasedPercent = amount / (_vestedAmount / 1e18);

    if (totalReleasedAmount >= _vestedAmount) {
      return 100;
    } else {
      return totalReleasedPercent * 100 || 0;
    }
  } else {
    return 0;
  }
};

export const getTotalStreamedAmount = (vestingDetails = {}) => {
  const { _cliff, _duration, _start, _vestedAmount } = vestingDetails || {};
  const endTime = Number(_start) + Number(_duration);
  const totalStreamedAmount =
    Number(_vestedAmount) -
    (Number(_vestedAmount) * (endTime - moment().unix())) / _duration;
  if (totalStreamedAmount !== NaN) {
    if (totalStreamedAmount >= _vestedAmount) {
      return _vestedAmount / 1e18;
    } else {
      return totalStreamedAmount / 1e18;
    }
  } else {
    return 0;
  }
};

export const getTotalReleasedPercent = (vestingDetails = {}) => {
  const { _vestedAmount, _releasedAmount } = vestingDetails || {};
  const totalReleased = _releasedAmount / _vestedAmount;
  return totalReleased * 100;
};

export const getTotalAmountAvaialableForRelease = (vestingDetails = {}) => {
  const { _vestedAmount = 0, _releasedAmount = 0 } = vestingDetails || {};
  const totalWithdrawn =
    getTotalStreamedAmount(vestingDetails) - Number(_releasedAmount) / 1e18;
  console.log('total withdrawn amiunt >>>>>', totalWithdrawn / 1e18);
  return totalWithdrawn;
};
