import React, { Component } from "react";
import { Provider } from "react-redux";
import { Alert, Button } from "antd";
import store from "./store";
import Route from "./Components";
import "./helpers/scripts/wallet";
import DeFiLogo from "./assets/images/Logo.png";
import { string } from "prop-types";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWrongNetworkAlert: false,
    };
  }

  accountAdd = "";
  networkId = window?.ethereum?.networkVersion;

  async componentDidMount() {
    this.timeId = setInterval(() => {
      if (window?.ethereum?.networkVersion != 137) {
        this.setState({
          showWrongNetworkAlert: true,
        });
      } else {
        this.setState({
          showWrongNetworkAlert: false,
        });
      }
    }, 3000);

    //Detext Chain change
    window?.ethereum?.on("chainChanged", (chainId) => {
      // Handle the new chain.
      // Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have good reason not to.
      window.location.reload();
    });
    //Detect Account  Chnaged
    window?.ethereum?.on("accountsChanged", async (accounts) => {
      console.log("account changed >>>", accounts, this.accountAdd);
      if (
        this.accountAdd &&
        this.accountAdd?.toLowerCase() !== accounts[0]?.toLowerCase()
      ) {
        this.accountAdd = accounts[0];
        window.location.reload();
      }
      this.accountAdd = accounts[0];
    });

    this.accountAdd = window?.ethereum?.selectedAddress;
  }

  componentWillUnmount() {
    clearInterval(this.timeId);
  }

  renderSwitchDeviceMessage = () => {
    return (
      <div className="flex flex-col items-center h-screen justify-center">
        <img
          src={DeFiLogo}
          alt=""
          className="defi-log"
          style={{
            height: "50px",
            marginBottom: "30px",
          }}
        />
        <p className="bold text-white text-xl">
          Please switch to a desktop device
        </p>
      </div>
    );
  };

  switchChains = async () => {
    try {
      const res = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(137).toString(16)}`,
            chainName: "Matic/Polygon",
            rpcUrls: ["https://rpc-mumbai.matic.today"],
            nativeCurrency: {
              name: "Matic/Polygon",
              symbol: "MATIC",
              decimals: 18,
            },
          },
        ],
      });
    } catch (error) {}
  };

  renderAlert = () => {
    return (
      <p className="mb0 semi-bold">
        Please switch to Matic/Polygon Network:{" "}
        <span
          onClick={this.switchChains}
          className="semi-bold ml10"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Switch Network
        </span>
      </p>
    );
  };

  render() {
    const { showWrongNetworkAlert = false } = this.state;
    return (
      <Provider store={store}>
        {showWrongNetworkAlert && window?.innerWidth > 700 && (
          <Alert message={this.renderAlert()} type="error" />
        )}
        {window?.innerWidth > 700 ? (
          <Route />
        ) : (
          this.renderSwitchDeviceMessage()
        )}
      </Provider>
    );
  }
}

export default App;
