import React from 'react';

function Revoked() {
  return (
    <div className='medium mt-10 flex justify-center items-center w100 h-3/5'>
      <p className='text-5xl text-white'>Your Contract has been revoked</p>
    </div>
  );
}

export default Revoked;
