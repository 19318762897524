import React, { useState, useEffect, useRef } from "react";
import { MODALS } from "../../constants";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserAddress } from "../../redux/user";
import { openModal } from "../../redux/modals";
import { Button } from "antd";
import DeFiLogo from "../../assets/images/Logo.png";
import DefiSkull from "../../assets/images/logo-skull.png";
import "./styles.less";

function Header({ history, location }) {
  const address = useRef();
  const dispatch = useDispatch();
  const [timeId, setTimeId] = useState(0);
  const { address: userAddress = "" } = useSelector((state) => state.user);
  useEffect(() => {
    const address = window?.ethereum?.selectedAddress;
    if (address) {
      dispatch(setUserAddress(address));
    }
    //If address not fetched while the component is mounted
    if (!address) {
      let timeId = setInterval(() => {
        const address = window?.ethereum?.selectedAddress;
        if (!userAddress) {
          console.log("header address check", userAddress);
          dispatch(setUserAddress(window?.ethereum?.selectedAddress));
        }
      }, 3000);
      setTimeId(timeId);
    }
    return () => {
      console.log("calling clear interval");
      clearInterval(timeId);
    };
  }, []);

  useEffect(() => {
    if (timeId) {
      clearInterval(timeId);
    }
  }, [userAddress]);

  const openConnectWallet = () => {
    dispatch(openModal(MODALS.CONNECT_WALLET));
  };

  return (
    <div className="flex w-full bg-black justify-between p-2 main-header">
      <img
        src={DeFiLogo}
        alt=""
        className="defi-log"
        style={{
          height: "50px",
        }}
      />
      {userAddress ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://staking.defieleven.com"
          >
            <Button
              className="defi11-primary-btn connect-wallet"
              onClick={openConnectWallet}
              style={{ marginRight: "10px" }}
            >
              Stake $D11
            </Button>
          </a>
          <div className="flex row justify-start items-center user-adress">
            <img
              src={DefiSkull}
              alt=""
              style={{
                height: "30px",
              }}
              className="user-img pointer"
              // onClick={openConnectWallet}
            />
            <p
              className="text-white medium text-md"
              style={{
                marginBottom: "0px",
                marginLeft: "10px",
              }}
            >
              {userAddress.substr(0, 5)}
              ...
              {userAddress.substr(
                userAddress.length - 5,
                userAddress.length - 5
              )}
            </p>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://staking.defieleven.com"
          >
            <Button
              className="defi11-primary-btn connect-wallet"
              onClick={openConnectWallet}
              style={{ marginRight: "10px" }}
            >
              Stake $D11
            </Button>
          </a>
          <Button
            className="defi11-primary-btn connect-wallet"
            onClick={openConnectWallet}
          >
            Connect Wallet
          </Button>
        </div>
      )}
    </div>
  );
}

// const HeaderWithRouter = withRouter(Header);
export default Header;
