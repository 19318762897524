import { vestingABI, factoryABI } from './abis';
import Web3 from 'web3';
// import * as _ from 'lodash';
let web3 = new Web3(Web3.givenProvider);

const d11 = '0xc58158c14d4757ef36ce25e493758f2fceedec5d'; //"0xd5931F0259E54072508487aDaEf7197EC421681D";
const factory = '0x6EbE5C7132168e36ec9085Ff705D6234Ba01A71C';

//Check if user has a vesting contract
export const findVesting = async (walletAddress) => {
  try {
    let contractInstance = new web3.eth.Contract(factoryABI, factory);
    // Call a function of the contract:
    let vestingContract = await contractInstance.methods
      .vestingContract(walletAddress)
      .call();
    // console.log('vestingContract', vestingContract);
    if (vestingContract == '0x0000000000000000000000000000000000000000') {
      return {
        success: false,
      };
    } else {
      let details = await contractInstance.methods
        .getDetails(vestingContract)
        .call();
      // console.log('details', details);
      // console.log({
      //   success: true,
      //   vestingContractAddress: vestingContract,
      //   details: details,
      // });
      return {
        success: true,
        vestingContractAddress: vestingContract,
        details: details,
      };
    }
  } catch (e) {
    console.log('e=', e);
    return {
      success: false,
    };
    return e;
  }
};

// export const release = async (vestingContract, account) => {
//   try {
//     const contractInstance = new web3.eth.Contract(vestingABI, vestingContract);
//     console.log('release funds callled>>>>>>', vestingContract, account);
//     // Call a function of the contract:
//     return new Promise((resolve, reject) => {
//       contractInstance.methods.release(d11).send(
//         {
//           from: account,
//           value: 0,
//         },
//         (err, res) => {
//           if (err) {
//             console.log('err=', err);
//             reject({ status: false, error: err });
//           } else {
//             console.log('res=', res);
//             resolve({ status: true, hash: res });
//           }
//         }
//       );
//     });
//   } catch (error) {
//     console.log('error>>>>1', error);
//   }
// };

export const release = async (vestingContract, amount, account) => {
  const contractInstance = new web3.eth.Contract(vestingABI, vestingContract);
  console.log('release test', vestingContract, amount, account);
  try {
    return new Promise((resolve, reject) => {
      contractInstance.methods
        .release(d11, web3.utils.toWei(amount.toString()))
        .send(
          {
            from: account,
            value: 0,
          },
          (err, res) => {
            if (err) {
              console.log('err=', err);
              reject({ status: false, error: err });
            } else {
              console.log('res=', res);
              resolve({ status: true, hash: res });
            }
          }
        );
    });
  } catch (error) {
    console.log('error>>>>1', error);
  }
};
