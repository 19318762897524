import React, { useState, useEffect } from 'react';
import Vesting from './VestingDetails';
import ConnectWallet from './Modals/ConnectWallet';
import WithdrawToken from './Modals/Withdraw';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { findVestingDetails } from '../redux/user';
import RevokedAccess from './Revoked';

const Routes = () => {
  const [timeId1, setTimeId1] = useState(null);
  const dispatch = useDispatch();
  const { address = '', vestingDetails: { _revoked = false } = {} } =
    useSelector((state) => state.user) || {};
  useEffect(() => {
    if (address) {
      dispatch(findVestingDetails(address));
      const timeId = setInterval(() => {
        dispatch(findVestingDetails(address));
      }, 30000);
      setTimeId1(timeId);
    }
    return () => {
      clearInterval(timeId1);
    };
  }, [address]);

  return (
    <>
      <Header />
      <div className='bg-black h-screen flex justify-center items-start'>
        {_revoked ? <RevokedAccess /> : <Vesting />}
      </div>
      <ConnectWallet />
      <WithdrawToken />
    </>
  );
};

export default Routes;
