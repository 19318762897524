import React from 'react';
import './styles.less';
import { connect } from 'react-redux';
import { Drawer, Button, Input, Form, message } from 'antd';
import { MODALS } from '../../../constants';
import { closeModal, withdrawToken } from '../../../redux/modals';
import { LoadingOutlined } from '@ant-design/icons';
import LeftArrow from '../../../assets/images/left-arrow.svg';
import validator from 'validator';
import {
  getTotalStreamedAmount,
  getTotalAmountAvaialableForRelease,
} from '../../../utils/streamDetails';
import moment from 'moment';
import { findVestingDetails } from '../../../redux/user';

class WithdrawTokens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.baseState = this.state;
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible === true && this.props.visible === false) {
      this.setState(this.baseState);
    }
  }

  setDrawerWidth = () => {
    if (window.innerWidth >= 1000) {
      return '50%';
    } else if (window.innerWidth <= 579) {
      return '90%';
    } else {
      return '80%';
    }
  };

  withdrawToken = () => {
    const { user: { vestingDetails = {} } = {} } = this.props;
    const { _cliff, _withdrawalAllowed = true } = vestingDetails || {};
    if (!_withdrawalAllowed) {
      message.error('Release is paused for now', 3);
      return;
    }
    if (_cliff > moment().unix()) {
      message.error(`Funds will release on ${new Date(_cliff * 1000)}`, 3);
      return;
    }
    this.formRef.current
      .validateFields()
      .then(async (values) => {
        const { user = {} } = this.props;
        const { address = '', vestingContractAddress = '' } = user || {};
        const { amount = 0 } = values || {};
        const { success = false } = await this.props.withdrawToken(
          vestingContractAddress,
          amount,
          address
        );
        if (success) {
          message.success('Transaction Successfull !', 5);
          this.props.findVestingDetails(address);
          this.props.closeModal();
        } else {
          message.error('Transaction Failed !', 5);
          this.props.closeModal();
        }
      })
      .catch((info) => console.log('info', info));
  };

  isvalidInteger = (rule, value, callback) => {
    const { user = {} } = this.props;
    const { vestingDetails = {} } = user || {};
    console.log('value', value);
    if (value) {
      if (
        !validator.isFloat(value, {
          min: 0.0001,
          max: getTotalAmountAvaialableForRelease(vestingDetails),
        })
      ) {
        return Promise.reject(new Error('Invalid Value'));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  renderMaxAmountIcon = () => {
    return (
      <div className='max-amt-value'>
        <p className='mb0 semi-bold pointer' onClick={this.setMaxValue}>
          MAX
        </p>
      </div>
    );
  };

  setMaxValue = () => {
    const { user = {} } = this.props;
    const { vestingDetails = {} } = user || {};
    console.log(
      'set max value callled',
      getTotalAmountAvaialableForRelease(vestingDetails)
    );
    this.formRef.current.setFieldsValue({
      amount: String(getTotalAmountAvaialableForRelease(vestingDetails)),
    });
  };

  render() {
    const { modal = {}, user = {} } = this.props;
    const {
      visible = false,
      modalType = '',
      isRequesting = false,
    } = modal || {};

    const { vestingDetails = {} } = user || {};
    const { _vestedAmount, _releasedAmount } = vestingDetails || {};
    return (
      <>
        <Drawer
          title={null}
          placement='right'
          maskClosable
          width={this.setDrawerWidth()}
          footer={null}
          closable={false}
          className='withdraw-token-drawer'
          visible={visible && modalType === MODALS.WITHDRAW_TOKENS}
          onClose={this.props.closeModal}
          destroyOnClose={true}>
          <div className='withdraw-token-container'>
            <div
              className='flex w100 header-icon justify-start items-center'
              onClick={this.props.closeModal}>
              <img src={LeftArrow} alt='' />
              <p className='mb-0 f14 semi-bold pointer ml-2'>Back</p>
            </div>
            <div className='flex flex-col withdraw-header mt10'>
              <p className='header-txt semi-bold f22'>Release Your Tokens</p>
            </div>
            <div className='flex flex-col vested-details'>
              <div className='flex flex-col total-token'>
                <p className='medium f14'>From a Total Vested</p>
                <p className='semi-bold f18 token-detail'>
                  {!isNaN(_vestedAmount / 1e18) ? _vestedAmount / 1e18 : 0} D11
                </p>
              </div>
              <div className='flex flex-col total-token'>
                <p className='medium f14'>Of which already streamed</p>
                <p className='semi-bold f18 token-detail'>
                  {!isNaN(getTotalStreamedAmount(vestingDetails))
                    ? getTotalStreamedAmount(vestingDetails)
                    : 0}{' '}
                  D11
                </p>
              </div>
              <div className='flex flex-col total-token'>
                <p className='medium f14'>
                  Of which already released to your wallet
                </p>
                <p className='semi-bold f18 token-detail'>
                  {!isNaN(_releasedAmount / 1e18) ? _releasedAmount / 1e18 : 0}{' '}
                  D11
                </p>
              </div>

              <div className='withdraw-amt'>
                <Form
                  layout='vertical'
                  name='upload-form'
                  ref={this.formRef}
                  requiredMark={false}>
                  <Form.Item
                    name='amount'
                    default
                    rules={[
                      {
                        required: true,
                        message: 'Withdraw Amount is required',
                      },
                      { validator: this.isvalidInteger },
                    ]}
                    className='input-number'>
                    <Input
                      type='number'
                      placeholder='Enter'
                      className='input-number'
                      // disabled={true}
                      addonAfter={this.renderMaxAmountIcon()}
                      suffix='D11'
                    />
                  </Form.Item>
                </Form>

                <Button
                  className={`defi11-primary-btn withdraw-btn`}
                  disabled={
                    getTotalAmountAvaialableForRelease(vestingDetails) <= 0
                  }
                  onClick={isRequesting ? null : this.withdrawToken}>
                  {isRequesting ? (
                    <LoadingOutlined
                      style={{ fontSize: 32, color: '#black' }}
                    />
                  ) : (
                    'RELEASE'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { modal, user } = state || {};
  return {
    modal,
    user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    withdrawToken: (vestingContractAddress, amount, address) =>
      dispatch(withdrawToken(vestingContractAddress, amount, address)),
    findVestingDetails: (address) => dispatch(findVestingDetails(address)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawTokens);
