import React, { useState, useRef, useEffect } from 'react';
import './styles.less';
import { Drawer } from 'antd';
import { closeModal } from '../../../redux/modals';
import { setUserAddress } from '../../../redux/user';
import metamaskImg from '../../../assets/images/metamask.png';
import walletConnect from '../../../assets/images/walletconnect.png';
import { useSelector, useDispatch } from 'react-redux';
import { connectWallet } from '../../../helpers/scripts/wallet';
import LeftArrow from '../../../assets/images/left-arrow.svg';
import { MODALS } from '../../../constants';

const ConnectWallet = () => {
  const mounted = useRef();
  const modal = useSelector((state) => state?.modal);
  const discover = useSelector((state) => state?.discover);
  const { visible = false, modalType = '', entity_id = null } = modal || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [visible]);

  const closeDrawer = () => {
    console.log('close modal callled');
    dispatch(closeModal());
  };

  const connectMetamaskWallet = async () => {
    try {
      const res = await connectWallet();
      const { status = false } = res || {};
      if (status) {
        dispatch(setUserAddress(window?.ethereum?.selectedAddress));
        closeDrawer();
      } else {
        dispatch(setUserAddress(''));
      }
    } catch (error) {
      dispatch(setUserAddress(''));
    }
  };

  const setDrawerWidth = () => {
    if (window.innerWidth >= 1000) {
      return '40%';
    } else if (window.innerWidth <= 579) {
      return '90%';
    } else {
      return '80%';
    }
  };

  return (
    <>
      <Drawer
        title={null}
        placement='right'
        maskClosable
        width={setDrawerWidth()}
        footer={null}
        closable={false}
        className='connect-wallet-drawer'
        visible={visible && modalType === MODALS.CONNECT_WALLET}
        onClose={closeDrawer}
        destroyOnClose={true}>
        <div className='connect-wallet-container'>
          <div
            className='flex w100 header-icon justify-start items-center'
            onClick={closeDrawer}>
            <img src={LeftArrow} alt='' />
            <p className='mb-0 f14 semi-bold pointer ml-2'>Back</p>
          </div>
          <div className='flex flex-col connect-header mt-2'>
            <p className='header-txt semi-bold f22'>Connect your wallet</p>
            <p className='m-0 regular f16'>
              Connect with one of available wallet providers or create a new
              wallet. What is wallet?
            </p>
            <div className='flex flex-col wallet-container'>
              <div
                className='wallet flex row pointer'
                onClick={connectMetamaskWallet}>
                <img src={metamaskImg} alt='' />
                <div className='flex flex-col justify-between'>
                  <p className='m-0 f18 semi-bold'>MetaMask</p>
                  <p className='f12 medium text-grey m-0'>
                    Connect to your MetaMask Wallet
                  </p>
                </div>
              </div>
              {/* <div className='wallet flex row disable'>
                <img src={walletConnect} alt='' />
                <div className='flex flex-col justify-between'>
                  <p className='m-0 f18 semi-bold'>WalletConnect</p>
                  <p className='f12 medium text-grey m0'>
                    Connect to your WalletConnect Wallet
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* {<Loader loading={loading} hash={hash} />} */}
      </Drawer>
    </>
  );
};
export default ConnectWallet;
